// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-board-of-directors-js": () => import("./../../../src/pages/board-of-directors.js" /* webpackChunkName: "component---src-pages-board-of-directors-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-chairmans-letters-js": () => import("./../../../src/pages/chairmans-letters.js" /* webpackChunkName: "component---src-pages-chairmans-letters-js" */),
  "component---src-pages-corporate-responsibility-js": () => import("./../../../src/pages/corporate-responsibility.js" /* webpackChunkName: "component---src-pages-corporate-responsibility-js" */),
  "component---src-pages-environment-js": () => import("./../../../src/pages/environment.js" /* webpackChunkName: "component---src-pages-environment-js" */),
  "component---src-pages-esg-js": () => import("./../../../src/pages/esg.js" /* webpackChunkName: "component---src-pages-esg-js" */),
  "component---src-pages-governace-js": () => import("./../../../src/pages/governace.js" /* webpackChunkName: "component---src-pages-governace-js" */),
  "component---src-pages-governance-js": () => import("./../../../src/pages/governance.js" /* webpackChunkName: "component---src-pages-governance-js" */),
  "component---src-pages-hong-kong-internships-js": () => import("./../../../src/pages/hong-kong-internships.js" /* webpackChunkName: "component---src-pages-hong-kong-internships-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-franchises-js": () => import("./../../../src/pages/international-franchises.js" /* webpackChunkName: "component---src-pages-international-franchises-js" */),
  "component---src-pages-philanthropic-programs-js": () => import("./../../../src/pages/philanthropic-programs.js" /* webpackChunkName: "component---src-pages-philanthropic-programs-js" */),
  "component---src-pages-responsible-sourcing-js": () => import("./../../../src/pages/responsible-sourcing.js" /* webpackChunkName: "component---src-pages-responsible-sourcing-js" */),
  "component---src-pages-senior-leadership-js": () => import("./../../../src/pages/senior-leadership.js" /* webpackChunkName: "component---src-pages-senior-leadership-js" */),
  "component---src-pages-social-js": () => import("./../../../src/pages/social.js" /* webpackChunkName: "component---src-pages-social-js" */),
  "component---src-pages-us-internships-js": () => import("./../../../src/pages/us-internships.js" /* webpackChunkName: "component---src-pages-us-internships-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

